<template>
	<v-card color="transparent ml-5 mr-5" flat tile>
		<v-card-title>
			Empresas
			<v-btn class="ml-8" v-bind="attrs" v-on="on" dark color="primary" elevation="0" @click="$router.replace({ name: 'CompanyNew' })">
				Adicionar
			</v-btn>
			<v-btn class="ml-4" v-bind="attrs" v-on="on" dark color="primary" elevation="0" @click="$router.replace({ name: 'CompanyBatch' })">
				Carregar
			</v-btn>
			<v-spacer></v-spacer>
			<v-spacer></v-spacer>
			<v-text-field v-model="search" append-icon="mdi-magnify" label="Filtrar" single-line hide-details clearable></v-text-field>
		</v-card-title>
		<v-data-table
			class="elevation-0"
			:headers="headers"
			:items="companies"
			:search="search"
			:items-per-page="20"
			:sort-by="['createdAt']"
			:sort-desc="[false]"
			calculate-widths="true"
		>
			<template v-slot:item.actions="{ item }">
				<v-btn
					@click="editCompany(item)"
					class="mr-1"
					v-bind="attrs"
					v-on="on"
					elevation="0"
					color="primary"
					min-width="0"
					max-width="40"
					min-height="0"
					max-height="40"
				>
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
				<v-btn
					@click="deleteCompany(item)"
					class="ml-1"
					v-bind="attrs"
					v-on="on"
					elevation="0"
					color="warning"
					min-width="0"
					max-width="40"
					min-height="0"
					max-height="40"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
	import { mapGetters } from "vuex";
	import { db } from "../../../firebase";

	export default {
		data() {
			return {
				search: null,
				headers: [
					{ text: "ID", value: "id" },
					{ text: "Profile", value: "defaultServiceProfile" },
					{ text: "Ativo", value: "active" },
					{ text: "Nome Comercial", value: "commercialName" },
					{ text: "Nome", value: "name" },
					{ text: "Contato", value: "phone" },
					{ text: "Ações", value: "actions", sortable: false },
				],
			};
		},
		computed: {
			...mapGetters({
				companies: "companies",
			}),
		},
		watch: {},
		methods: {
			editCompany(company) {
				this.$store.dispatch("setCompanyId", company.id);
				this.$router.replace({ name: "Company" });
			},
			deleteCompany(company) {
				this.$swal
					.fire({
						title: "Apagar?",
						text: "Você deseja apagar essa empresa?",
						icon: "warning",
						showCancelButton: true,
						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: "Sim",
						cancelButtonText: "Não",
					})
					.then((result) => {
						if (result.value) {
							db.collection("companies")
								.doc(company.id)
								.delete()
								.then(() => {
									this.$router.replace({ name: "Companies" });
								});
						}
					});
			},
		},
		created() {
			this.$store.dispatch("bindCompanies");
		},
	};
</script>

import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    serviceProfiles: [],
    serviceProfileId: null,
  },
  mutations: {
    SET_SERVICE_PROFILE_ID(state, value) {
      state.serviceProfileId = value;
    },
  },
  actions: {
    setServiceProfileId({ commit }, id) {
      commit("SET_SERVICE_PROFILE_ID", id);
    },
    bindServiceProfiles: firestoreAction((context) => {
      let ref = db.collection("service-profiles");
      context.bindFirestoreRef("serviceProfiles", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    serviceProfiles(state) {
      return state.serviceProfiles;
    },
    serviceProfileId(state) {
      return state.serviceProfileId;
    },
  },
};

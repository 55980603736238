<template>
  <v-card color="transparent ml-5 mr-5" flat tile>
    <v-card-title>
      Disease Knowledge Base
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab href="#geral">Geral</v-tab>
        <v-tab href="#details">Details</v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item value="geral" class="mt-4">
            <v-text-field
              label="Disease Id"
              placeholder="id"
              outlined
              v-model="id"
            ></v-text-field>
          </v-tab-item>
          <v-tab-item value="details">
            <prism-editor
              class="my-editor height"
              v-model="diseaseKnowledgeBase"
              :highlight="highlighter"
              line-numbers
            ></prism-editor
          ></v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="ml-8 mt-10"
        v-bind="attrs"
        v-on="on"
        dark
        color="secondary"
        elevation="0"
        @click="saveDiseaseKnowledgeBase()"
      >
        Salvar
      </v-btn>
      <v-btn
        class="ml-8 mt-10"
        v-bind="attrs"
        v-on="on"
        dark
        color="warning"
        elevation="0"
        @click="$router.replace({ name: 'DiseaseKnowledgeBase' })"
      >
        Voltar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../../../firebase";

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-funky.css"; // import syntax highlighting styles
const beautify = require("json-beautify");
let emptyDiseaseKnowledgeBase = require("./diseaseKnowledgeBase.json");

export default {
  data() {
    return {
      tab: null,
      id: null,
      diseaseKnowledgeBase: null,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    highlighter(code) {
      return highlight(code, languages.json); //returns html
    },
    saveDiseaseKnowledgeBase() {
      db.collection("disease-knowledge-base")
        .doc(this.id)
        .set(JSON.parse(this.diseaseKnowledgeBase))
        .then(() => {
          this.$router.replace({ name: "DiseaseKnowledgeBase" });
        });
    },
  },
  created() {
    this.diseaseKnowledgeBase = beautify(emptyDiseaseKnowledgeBase, null, 2, 0);
  },
};
</script>

<style>
.height {
  height: 650px;
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"transparent ml-5 mr-5","flat":"","tile":""}},[_c('v-card-title',[_vm._v(" Data Views "),_c('v-btn',_vm._g(_vm._b({staticClass:"ml-8",attrs:{"dark":"","color":"primary","elevation":"0"},on:{"click":function($event){return _vm.$router.replace({ name: 'DataViewNew' })}}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" Adicionar ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filtrar","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.dataViews,"search":_vm.search,"items-per-page":40,"sort-by":['text'],"sort-desc":[false],"calculate-widths":"true"},scopedSlots:_vm._u([{key:"item.activeResource",fn:function(ref){
var item = ref.item;
return [(item.activeResource)?_c('span',[_vm._v(" "+_vm._s(item.activeResource)+" ")]):_c('span',{staticClass:"text-center"},[_vm._v(" - ")])]}},{key:"item.activeValue",fn:function(ref){
var item = ref.item;
return [(item.activeValue)?_c('span',[_vm._v(" "+_vm._s(item.activeValue)+" ")]):_c('span',{staticClass:"text-center"},[_vm._v(" - ")])]}},{key:"item.infoType",fn:function(ref){
var item = ref.item;
return [(item.infoType)?_c('span',[_vm._v(" "+_vm._s(item.infoType)+" ")]):_c('span',{staticClass:"text-center"},[_vm._v(" - ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editDataView(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteDataView(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
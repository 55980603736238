import Resources from "../../components/functional/resource/Resources.vue";
import Resource from "../../components/functional/resource/Resource.vue";
import ResourceNew from "../../components/functional/resource/New.vue";

export default [
  {
    path: "resources",
    name: "Resources",
    component: Resources,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "resource",
    name: "Resource",
    component: Resource,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "resourceNew",
    name: "ResourceNew",
    component: ResourceNew,
    meta: {
      requiresAuth: true,
    },
  },
];

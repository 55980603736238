import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    diseaseKnowledgeBase: [],
    diseaseKnowledgeBaseId: null,
  },
  mutations: {
    SET_DISEASE_KNOWLEDGE_BASE_ID(state, value) {
      state.diseaseKnowledgeBaseId = value;
    },
  },
  actions: {
    setDiseaseKnowledgeBaseId({ commit }, id) {
      commit("SET_DISEASE_KNOWLEDGE_BASE_ID", id);
    },
    bindDiseaseKnowledgeBase: firestoreAction((context) => {
      let ref = db.collection("disease-knowledge-base");
      context.bindFirestoreRef("diseaseKnowledgeBase", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    diseaseKnowledgeBase(state) {
      return state.diseaseKnowledgeBase;
    },
    diseaseKnowledgeBaseId(state) {
      return state.diseaseKnowledgeBaseId;
    },
  },
};

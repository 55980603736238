import CustomerIds from "../../components/functional/customerIds/CustomerIds.vue";

export default [
  {
    path: "customerIds",
    name: "CustomerIds",
    component: CustomerIds,
    meta: {
      requiresAuth: true,
    },
  },
];

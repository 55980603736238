import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    anamnesis: [],
    anamnesisId: null,
  },
  mutations: {
    SET_ANAMNESIS_ID(state, value) {
      state.anamnesisId = value;
    },
  },
  actions: {
    setAnamnesisId({ commit }, id) {
      commit("SET_ANAMNESIS_ID", id);
    },
    bindAnamnesis: firestoreAction((context) => {
      let ref = db.collection("anamnesis");
      context.bindFirestoreRef("anamnesis", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    anamnesis(state) {
      return state.anamnesis;
    },
    anamnesisId(state) {
      return state.anamnesisId;
    },
  },
};

import Views from "../../components/functional/views/Views.vue";
import View from "../../components/functional/views/View.vue";
import ViewNew from "../../components/functional/views/New.vue";

export default [
  {
    path: "views",
    name: "Views",
    component: Views,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "view",
    name: "View",
    component: View,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "viewNew",
    name: "ViewNew",
    component: ViewNew,
    meta: {
      requiresAuth: true,
    },
  },
];

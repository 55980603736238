import CalculatedFieldss from "../../components/functional/calculatedFields/CalculatedFields.vue";
import calculatedField from "../../components/functional/calculatedFields/CalculatedField.vue";
import calculatedFieldNew from "../../components/functional/calculatedFields/New.vue";

export default[
    {
        path: "calculatedFields",
        name: "CalculatedFields",
        component: CalculatedFieldss,
        meta: {
            requiresAuth: true
        }
    }, {
        path: "calculatedField",
        name: "CalculatedField",
        component: calculatedField,
        meta: {
            requiresAuth: true
        }
    }, {
        path: "calculatedFieldNew",
        name: "CalculatedFieldNew",
        component: calculatedFieldNew,
        meta: {
            requiresAuth: true
        }
    },
];

<template>
	<v-card color="transparent ml-5 mr-5" flat tile>
		<v-card-title>
			Monitor
			<v-spacer></v-spacer>
		</v-card-title>
		<v-card-text>
			<prism-editor class="my-editor height" v-model="monitor" :highlight="highlighter" line-numbers></prism-editor>
		</v-card-text>
		<v-card-actions>
			<v-btn class="ml-8 mt-10" v-bind="attrs" v-on="on" dark color="secondary" elevation="0" @click="addMonitor()">
				Adicionar
			</v-btn>
			<v-btn class="ml-8 mt-10" v-bind="attrs" v-on="on" dark color="warning" elevation="0" @click="$router.replace({ name: 'Monitors' })">
				Voltar
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	import { mapGetters } from "vuex";
	import { db } from "../../../firebase";

	import { highlight, languages } from "prismjs/components/prism-core";
	import "prismjs/components/prism-clike";
	import "prismjs/components/prism-json";
	import "prismjs/themes/prism-funky.css";
	const beautify = require("json-beautify");
	const generator = require("generate-password");
	const crypto = require("../../../utils/crypto");
	let emptyMonitor = require("./monitor.json");

	export default {
		data() {
			return {
				monitor: null,
			};
		},
		computed: {
			...mapGetters({}),
		},
		watch: {},
		methods: {
			highlighter(code) {
				return highlight(code, languages.json); //returns html
			},
			addMonitor() {
				this.monitor = JSON.parse(this.monitor);
				db.collection("monitors")
					.add(this.monitor)
					.then(() => {
						this.$router.replace({ name: "Monitors" });
					})
					.catch((error) => {
						console.error(error);
					});
			},
		},
		created() {
			this.monitor = beautify(emptyMonitor, null, 2, 0);
		},
	};
</script>

<style>
	.height {
		height: 650px;
	}
	/* required class */
	.my-editor {
		/* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
		background: #2d2d2d;
		color: #ccc;

		/* you must provide font-family font-size line-height. Example: */
		font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
		font-size: 14px;
		line-height: 1.5;
		padding: 5px;
	}

	/* optional class for removing the outline */
	.prism-editor__textarea:focus {
		outline: none;
	}
</style>

import Providers from "../../components/functional/provider/Providers.vue";
import Provider from "../../components/functional/provider/Provider.vue";
import ProviderNew from "../../components/functional/provider/New.vue";

export default [
  {
    path: "providers",
    name: "Providers",
    component: Providers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "provider",
    name: "Provider",
    component: Provider,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "providerNew",
    name: "ProviderNew",
    component: ProviderNew,
    meta: {
      requiresAuth: true,
    },
  },
];

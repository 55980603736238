import Vue from "vue";
import App from "./App.vue";

import LoadScript from "vue-plugin-load-script";
Vue.use(LoadScript);

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles
Vue.component("PrismEditor", PrismEditor);

import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";

import { auth } from "./firebase";

import "./scss/main.scss";

Vue.config.productionTip = true;
Vue.config.silent = true;
Vue.config.devtools = true;

Vue.use(VueSweetalert2);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});

const moment = require("moment");

require("moment/locale/pt");
Vue.use(require("vue-moment"), {
  moment,
});

var app;
auth.onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
      metaInfo: () => ({
        title: "ICareYou Administration Console",
      }),
    }).$mount("#app");
  }

  if (user) {
    user.getIdTokenResult().then((idTokenResult) => {
      user.admin = idTokenResult.claims.admin;
      if (user.admin) {
        router.replace({ name: "Dashboard" });
      } else {
        router.replace({ name: "Login" });
      }
    });
  }
});

import Customers from "../../components/functional/customer/Customers.vue";
import Customer from "../../components/functional/customer/Customer.vue";
import CustomerNew from "../../components/functional/customer/New.vue";
import CustomerBatch from "../../components/functional/customer/Batch.vue";

export default [
  {
    path: "customers",
    name: "Customers",
    component: Customers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "customer",
    name: "Customer",
    component: Customer,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "customerNew",
    name: "CustomerNew",
    component: CustomerNew,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "customerBatch",
    name: "CustomerBatch",
    component: CustomerBatch,
    meta: {
      requiresAuth: true,
    },
  },
];

import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    datasources: [],
    datasourceId: null,
  },
  mutations: {
    SET_DATASOURCE_ID(state, value) {
      state.datasourceId = value;
    },
  },
  actions: {
    setDatasourceId({ commit }, id) {
      commit("SET_DATASOURCE_ID", id);
    },
    bindDatasources: firestoreAction((context) => {
      let ref = db.collection("datasources");
      context.bindFirestoreRef("datasources", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    datasources(state) {
      return state.datasources;
    },
    datasourceId(state) {
      return state.datasourceId;
    },
  },
};

<template>
	<v-dialog v-model="dialog" persistent max-width="1200" class="dialog">
		<template v-slot:activator="{ on, attrs }">
			<v-btn class="ml-2" color="success" dark v-bind="attrs" elevation="0" v-on="on">
				Memed
			</v-btn>
		</template>
		<v-card flat height="100%" width="100%">
			<v-card-title>
				Professional
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-text>
				<v-tabs v-model="tab">
					<v-tab href="#create">Memed</v-tab>
					<v-tab href="#delete">Delete</v-tab>
					<v-tab href="#token">Token</v-tab>
					<v-tab href="#protocols">Protocols</v-tab>
					<v-tab href="#print">Print</v-tab>
					<v-tab href="#city">Cidade</v-tab>
					<v-tab href="#specialty">Especialidades</v-tab>
					<v-tabs-items v-model="tab">
						<v-tab-item value="create" class="mt-4">
							<v-row>
								<v-col><span>Request</span><prism-editor id="professionalRequest" class="my-editor api"
										v-model="professionalRequest" :highlight="highlighter"
										line-numbers></prism-editor>
								</v-col>
								<v-col><span>Response</span>
									<prism-editor id="memedProfessional" class="my-editor api"
										v-model="professionalResponse" :highlight="highlighter"
										line-numbers></prism-editor>
								</v-col>
							</v-row>
							<v-card-actions>
								<v-btn class="ml-n2" dark color="secondary" elevation="0" @click="createProfessional()">
									Criar Profissional
								</v-btn>
								<v-btn class="ml-2" dark color="warning" elevation="0" @click="clearProfessional()">
									Limpar
								</v-btn>
								<v-btn class="ml-2" dark elevation="0" color="primary" @click="dialog = false; init();">
									Fechar
								</v-btn>
							</v-card-actions>
						</v-tab-item>
						<v-tab-item value="delete">
							<v-row>
								<v-col><span>Request</span><prism-editor id="deleteProfessionalRequest"
										class="my-editor api" v-model="deleteProfessionalRequest"
										:highlight="highlighter" line-numbers></prism-editor>
								</v-col>
								<v-col><span>Response</span>
									<prism-editor id="deleteProfessionalResponse" class="my-editor api"
										v-model="deleteProfessionalResponse" :highlight="highlighter"
										line-numbers></prism-editor>
								</v-col>
							</v-row>
							<v-card-actions>
								<v-btn class="ml-n2" dark color="secondary" elevation="0" @click="deleteProfessional()">
									Executar
								</v-btn>
								<v-btn class="ml-2" dark color="warning" elevation="0"
									@click="clearDeleteProfessional()">
									Limpar
								</v-btn>
								<v-btn class="ml-2" dark elevation="0" color="primary" @click="dialog = false; init();">
									Fechar
								</v-btn>
							</v-card-actions>
						</v-tab-item>
						<v-tab-item value="token">
							<v-row>
								<v-col><span>Request</span><prism-editor id="tokenRequest" class="my-editor api"
										v-model="tokenRequest" :highlight="highlighter" line-numbers></prism-editor>
								</v-col>
								<v-col><span>Response</span>
									<prism-editor id="tokenResponse" class="my-editor api" v-model="tokenResponse"
										:highlight="highlighter" line-numbers></prism-editor>
								</v-col>
							</v-row>
							<v-card-actions>
								<v-btn class="ml-n2" dark color="secondary" elevation="0" @click="callTokenApi()">
									Executar
								</v-btn>
								<v-btn class="ml-2" dark color="warning" elevation="0" @click="clearToken()">
									Limpar
								</v-btn>
								<v-btn class="ml-2" dark elevation="0" color="primary" @click="dialog = false; init();">
									Fechar
								</v-btn>
							</v-card-actions>
						</v-tab-item>
						<v-tab-item value="protocols">
							<v-row>
								<v-col><span>Request</span><prism-editor id="protocolsRequest" class="my-editor api"
										v-model="protocolsRequest" :highlight="highlighter" line-numbers></prism-editor>
								</v-col>
								<v-col><span>Response</span>
									<prism-editor id="protocolsResponse" class="my-editor api"
										v-model="protocolsResponse" :highlight="highlighter"
										line-numbers></prism-editor>
								</v-col>
							</v-row>
							<v-card-actions>
								<v-btn class="ml-n2" dark color="secondary" elevation="0" @click="callProtocolsApi()">
									Executar
								</v-btn>
								<v-btn class="ml-2" dark color="warning" elevation="0" @click="clearProtocols()">
									Limpar
								</v-btn>
								<v-btn class="ml-2" dark elevation="0" color="primary" @click="dialog = false; init();">
									Fechar
								</v-btn>
							</v-card-actions>
						</v-tab-item>
						<v-tab-item value="print">
							<v-row>
								<v-col><span>Request</span><prism-editor id="printRequest" class="my-editor api"
										v-model="printRequest" :highlight="highlighter" line-numbers></prism-editor>
								</v-col>
								<v-col><span>Response</span>
									<prism-editor id="printResponse" class="my-editor api" v-model="printResponse"
										:highlight="highlighter" line-numbers></prism-editor>
								</v-col>
							</v-row>
							<v-card-actions>
								<v-btn class="ml-n2" dark color="secondary" elevation="0" @click="callPrintApi()">
									Executar
								</v-btn>
								<v-btn class="ml-2" dark color="warning" elevation="0" @click="clearPrint()">
									Limpar
								</v-btn>
								<v-btn class="ml-2" dark elevation="0" color="primary" @click="dialog = false; init();">
									Fechar
								</v-btn>
							</v-card-actions>
						</v-tab-item>
						<v-tab-item value="city">
							<v-row>
								<v-col><span>Request</span><prism-editor id="cityRequest" class="my-editor api"
										v-model="cityRequest" :highlight="highlighter" line-numbers></prism-editor>
								</v-col>
								<v-col><span>Response</span>
									<prism-editor id="cityResponse" class="my-editor api" v-model="cityResponse"
										:highlight="highlighter" line-numbers></prism-editor>
								</v-col>
							</v-row>
							<v-card-actions>
								<v-btn class="ml-n2" dark color="secondary" elevation="0" @click="callCityApi()">
									Executar
								</v-btn>
								<v-btn class="ml-2" dark color="warning" elevation="0" @click="clearCity()">
									Limpar
								</v-btn>
								<v-btn class="ml-2" dark elevation="0" color="primary" @click="dialog = false; init();">
									Fechar
								</v-btn>
							</v-card-actions>
						</v-tab-item>
						<v-tab-item value="specialty">
							<prism-editor id="specialties" class="my-editor console" v-model="specialties"
								:highlight="highlighter" line-numbers></prism-editor>
							<v-card-actions>
								<v-btn class="ml-n2" dark elevation="0" color="primary"
									@click="dialog = false; init();">
									Fechar
								</v-btn>
							</v-card-actions>
						</v-tab-item>
					</v-tabs-items>
				</v-tabs>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { functions, db } from "../../../firebase";

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-funky.css"; // import syntax highlighting styles
const beautify = require("json-beautify");
import { formatToCPF } from "brazilian-values";

const emptyMemedProfessionalRequest = require("./memedProfessional.json");
const emptyTokenRequest = require("./tokenRequest.json");
const emptyDeleteProfessionalRequest = require("./deleteProfessionalRequest.json");
const emptyCityRequest = require("./cityRequest.json");
const emptyProtocolsRequest = require("./protocolsRequest.json");
const emptyPrintRequest = require("./printRequest.json");

export default {
	data() {
		return {
			dialog: false,
			tab: null,
			professionalRequest: null,
			professionalResponse: null,
			deleteProfessionalRequest: null,
			deleteProfessionalResponse: null,
			tokenResponse: null,
			tokenRequest: null,
			protocolsResponse: null,
			protocolsRequest: null,
			printResponse: null,
			printRequest: null,
			cityResponse: null,
			cityRequest: null,
			specialties: null,
		};
	},
	computed: {
		...mapGetters({
			professionalId: "professionalId",
		}),
	},
	watch: {},
	methods: {
		highlighter(code) {
			return highlight(code, languages.json);
		},
		createProfessional() {
			this.professionalResponse = null;

			let createProfessional = functions.httpsCallable("memed-create-professional-api");

			this.professionalRequest = JSON.parse(this.professionalRequest);
			createProfessional(this.professionalRequest)
				.then((result) => {
					this.professionalResponse = beautify(result.data, null, 2, 0);
				})
				.catch((error) => {
					console.error(error);
					this.professionalResponse = error.message;
				});
		},
		callTokenApi() {
			this.tokenResponse = null;

			let getToken = functions.httpsCallable("memed-get-token-api");
			this.tokenRequest = JSON.parse(this.tokenRequest);
			getToken(this.tokenRequest)
				.then((result) => {
					this.tokenResponse = beautify(result.data, null, 2, 0);
				})
				.catch((error) => {
					console.error(error.message);
					this.tokenResponse = error.message;
				});
		},
		callProtocolsApi() {
			this.protocolsResponse = null;

			let getProtocols = functions.httpsCallable("memed-get-protocols-api");
			this.protocolsRequest = JSON.parse(this.protocolsRequest);
			getProtocols(this.protocolsRequest)
				.then((result) => {
					this.protocolsResponse = beautify(result.data, null, 2, 0);
				})
				.catch((error) => {
					console.error(error.message);
					this.protocolsResponse = error.message;
				});
		},
		callPrintApi() {
			this.printResponse = null;

			let setPrint = functions.httpsCallable("memed-set-print-api");
			this.printRequest = JSON.parse(this.printRequest);
			setPrint(this.printRequest)
				.then((result) => {
					this.printResponse = beautify(result.data, null, 2, 0);
				})
				.catch((error) => {
					console.error(error.message);
					this.printResponse = error.message;
				});
		},
		deleteProfessional() {
			this.deleteProfessionalResponse = null;

			let deleteProfessional = functions.httpsCallable("memed-delete-professional-api");

			this.deleteProfessionalRequest = JSON.parse(this.deleteProfessionalRequest);
			deleteProfessional(this.deleteProfessionalRequest)
				.then((result) => {
					this.deleteProfessionalResponse = beautify(result, null, 2, 0);
				})
				.catch((error) => {
					console.error(error);
					this.deleteProfessionalResponse = error.message;
				});
		},
		callCityApi() {
			this.cityResponse = null;

			let getCity = functions.httpsCallable("memed-get-city-api");

			this.cityRequest = JSON.parse(this.cityRequest);
			getCity({
				city: this.cityRequest.city,
			})
				.then((result) => {
					this.cityResponse = beautify(result.data, null, 2, 0);
				})
				.catch((error) => {
					console.error(error);
					this.cityResponse = error.message;
				});
		},
		clearProfessional() {
			this.init();
		},
		clearPrint() {
			this.init();
		},
		clearCity() {
			this.init();
		},
		clearToken() {
			this.init();
		},
		clearProtocols() {
			this.init();
		},
		clearDeleteProfessional() {
			this.init();
		},
		async init() {

			this.professionalRequest = "";
			this.professionalResponse = "";
			this.deleteProfessionalRequest = "";
			this.deleteProfessionalResponse = "";
			this.tokenResponse = "";
			this.tokenRequest = "";
			this.protocolsResponse = "";
			this.protocolsRequest = "";
			this.printResponse = "";
			this.printRequest = "";
			this.cityResponse = "";
			this.cityRequest = "";
			this.specialties = "";

			//Professional
			let request = JSON.parse(JSON.stringify(emptyMemedProfessionalRequest));

			let professional = await db
				.collection("professionals")
				.doc(this.professionalId)
				.get();

			request.data.attributes.external_id = professional.id;
			request.data.attributes.nome = professional.data().name;
			request.data.attributes.sobrenome = professional.data().middleName + " " + professional.data().lastName;
			request.data.attributes.data_nascimento = professional.data().birthdate;
			request.data.attributes.cpf = professional.data().cpf;
			request.data.attributes.email = professional.data().email;
			request.data.attributes.uf = professional.data().memed.state;
			request.data.attributes.sexo = professional.data().gender;
			request.data.attributes.crm = professional.data().professionalId;
			request.data.relationships.cidade.data.id = professional.data().memed.city;
			request.data.relationships.especialidade.data.id = professional.data().memed.specialty;

			this.professionalRequest = beautify(request, null, 2, 0);

			let token = JSON.parse(JSON.stringify(emptyTokenRequest));
			token.type = 'cpf';
			token.professional = this.professionalId;
			this.tokenRequest = beautify(token, null, 2, 0);

			let protocols = JSON.parse(JSON.stringify(emptyProtocolsRequest));
			protocols.professional = this.professionalId;
			this.protocolsRequest = beautify(protocols, null, 2, 0);

			let print = JSON.parse(JSON.stringify(emptyPrintRequest));
			print.professional = this.professionalId;
			this.printRequest = beautify(print, null, 2, 0);

			let deleteProfessional = JSON.parse(JSON.stringify(emptyDeleteProfessionalRequest));
			deleteProfessional.professional = this.professionalId;
			this.deleteProfessionalRequest = beautify(deleteProfessional, null, 2, 0);




			this.cityRequest = beautify(JSON.parse(JSON.stringify(emptyCityRequest)), null, 2, 0);

			let getSpecialties = functions.httpsCallable("memed-get-specialties-api");

			getSpecialties()
				.then((result) => {
					this.specialties = beautify(result.data, null, 2, 0);
				})
				.catch((error) => {
					this.specialties = beautify(error.message, null, 2, 0);
				});
		}
	},

	async created() {
		this.init();
	},
};
</script>

<style>
.dialog {
	min-height: 600px;
}

.console {
	max-height: 300px;
}

.api {
	max-width: 550px;
}

.console {
	min-height: 400px;
}

/* required class */
.my-editor {
	/* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
	background: #2d2d2d;
	color: #ccc;

	/* you must provide font-family font-size line-height. Example: */
	font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
	font-size: 14px;
	line-height: 1.5;
	padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
	outline: none;
}
</style>

<template>
	<v-card color="transparent ml-5 mr-5" flat tile>
		<v-card-title>
			Perfil de Serviço
			<v-spacer></v-spacer>
		</v-card-title>
		<v-card-text>
			<prism-editor class="my-editor height" v-model="service" :highlight="highlighter" line-numbers></prism-editor>
		</v-card-text>
		<v-card-actions>
			<v-btn class="ml-8 mt-10" v-bind="attrs" v-on="on" dark color="secondary" elevation="0" @click="saveServiceProfile()">
				Salvar
			</v-btn>
			<v-btn class="ml-8 mt-10" v-bind="attrs" v-on="on" dark color="warning" elevation="0" @click="$router.replace({ name: 'ServiceProfiles' })">
				Voltar
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	import { mapGetters } from "vuex";
	import { db } from "../../../firebase";

	// import highlighting library (you can use any library you want just return html string)
	import { highlight, languages } from "prismjs/components/prism-core";
	import "prismjs/components/prism-clike";
	import "prismjs/components/prism-json";
	import "prismjs/themes/prism-funky.css"; // import syntax highlighting styles
	const beautify = require("json-beautify");

	export default {
		data() {
			return {
				service: null,
			};
		},
		computed: {
			...mapGetters({
				serviceProfileId: "serviceProfileId",
			}),
		},
		watch: {},
		methods: {
			highlighter(code) {
				return highlight(code, languages.json); //returns html
			},
			saveServiceProfile() {
				db.collection("service-profiles")
					.doc(this.serviceProfileId)
					.update(JSON.parse(this.service))
					.then(() => {
						this.$router.replace({ name: "ServiceProfiles" });
					});
			},
		},
		created() {
			db.collection("service-profiles")
				.doc(this.serviceProfileId)
				.get()
				.then((serv) => {
					this.service = beautify(serv.data(), null, 2, 0);
				});
		},
		mounted() {},
	};
</script>
<style>
	.height {
		height: 650px;
	}
	/* required class */
	.my-editor {
		/* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
		background: #2d2d2d;
		color: #ccc;

		/* you must provide font-family font-size line-height. Example: */
		font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
		font-size: 14px;
		line-height: 1.5;
		padding: 5px;
	}

	/* optional class for removing the outline */
	.prism-editor__textarea:focus {
		outline: none;
	}
</style>

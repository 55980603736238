import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    survey: [],
    surveyId: null,
  },
  mutations: {
    SET_SURVEY_ID(state, value) {
      state.surveyId = value;
    },
  },
  actions: {
    setSurveyId({ commit }, id) {
      commit("SET_SURVEY_ID", id);
    },
    bindSurvey: firestoreAction((context) => {
      let ref = db.collection("survey");
      context.bindFirestoreRef("survey", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    survey(state) {
      return state.survey;
    },
    surveyId(state) {
      return state.surveyId;
    },
  },
};

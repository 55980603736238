import WhatsappTemplates from "../../components/functional/whatsappTemplate/Templates.vue";
import WhatsappTemplate from "../../components/functional/whatsappTemplate/Template.vue";

export default [
  {
    path: "whatsappTemplates",
    name: "WhatsappTemplates",
    component: WhatsappTemplates,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "whatsappTemplate",
    name: "WhatsappTemplate",
    component: WhatsappTemplate,
    meta: {
      requiresAuth: true,
    },
  },
];

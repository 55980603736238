import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    views: [],
    viewId: null,
  },
  mutations: {
    SET_VIEW_ID(state, value) {
      state.viewId = value;
    },
  },
  actions: {
    setViewId({ commit }, id) {
      commit("SET_VIEW_ID", id);
    },
    bindViews: firestoreAction((context) => {
      let ref = db.collection("views");
      context.bindFirestoreRef("views", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    views(state) {
      return state.views;
    },
    viewId(state) {
      return state.viewId;
    },
  },
};

import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
	state: {
		primaryCares: [],
		primaryCareId: null,
	},
	mutations: {
		SET_PRIMARY_CARE_ID(state, value) {
			state.primaryCareId = value;
		},
	},
	actions: {
		setPrimaryCareId({ commit }, id) {
			commit("SET_PRIMARY_CARE_ID", id);
		},
		bindPrimaryCares: firestoreAction((context) => {
			let ref = db.collection("primary-care");
			context.bindFirestoreRef("primaryCares", ref, {
				wait: true,
				reset: true,
			});
		}),
	},
	getters: {
		primaryCares(state) {
			return state.primaryCares;
		},
		primaryCareId(state) {
			return state.primaryCareId;
		},
	},
};

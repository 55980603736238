<template>
  <v-card color="transparent ml-5 mr-5" flat tile>
    <v-card-title>
      Lista de Contatos
      <v-btn
        class="ml-8"
        v-bind="attrs"
        v-on="on"
        dark
        color="primary"
        elevation="0"
        @click="$router.replace({ name: 'ContactNew' })"
      >
        Adicionar
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Filtrar"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="elevation-0"
      :headers="headers"
      :items="contacts"
      :search="search"
      :items-per-page="20"
      :sort-by="['createdAt']"
      :sort-desc="[false]"
      calculate-widths="true"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" color="primary" @click="editContact(item)">
          mdi-pencil
        </v-icon>
        <v-icon small class="mr-2" color="error" @click="deleteContact(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../../../firebase";

export default {
  data() {
    return {
      search: null,
      headers: [
        { text: "ID", value: "id" },
        { text: "Query Id", value: "queryId" },
        { text: "Descrição", value: "description" },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      contacts: "contacts",
    }),
  },
  watch: {},
  methods: {
    editContact(contact) {
      this.$store.dispatch("setContactId", contact.id);
      this.$router.replace({ name: "Contact" });
    },
    deleteContact(contact) {
      this.$swal
        .fire({
          title: "Apagar?",
          text: "Você deseja apagar essa lista de contatos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then((result) => {
          if (result.value) {
            db.collection("contacts")
              .doc(contact.id)
              .delete()
              .then(() => {
                this.$router.replace({ name: "Contacts" });
              });
          }
        });
    },
  },
  created() {
    this.$store.dispatch("bindContacts");
  },
};
</script>

import Contacts from "../../components/functional/contacts/Contacts.vue";
import Contact from "../../components/functional/contacts/Contact.vue";
import ContactNew from "../../components/functional/contacts/New.vue";

export default [
  {
    path: "contacts",
    name: "Contacts",
    component: Contacts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "contact",
    name: "Contact",
    component: Contact,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "contactNew",
    name: "ContactNew",
    component: ContactNew,
    meta: {
      requiresAuth: true,
    },
  },
];

import Profiles from "../../components/functional/profile/Profiles.vue";
import Profile from "../../components/functional/profile/Profile.vue";
import ProfileNew from "../../components/functional/profile/New.vue";

export default [
  {
    path: "profiles",
    name: "Profiles",
    component: Profiles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "profileNew",
    name: "ProfileNew",
    component: ProfileNew,
    meta: {
      requiresAuth: true,
    },
  },
];

import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    tcles: [],
    tcleId: null,
  },
  mutations: {
    SET_TCLE_ID(state, value) {
      state.tcleId = value;
    },
  },
  actions: {
    setTcleId({ commit }, id) {
      commit("SET_TCLE_ID", id);
    },
    bindTcles: firestoreAction((context) => {
      let ref = db.collection("tcle");
      context.bindFirestoreRef("tcles", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    tcles(state) {
      return state.tcles;
    },
    tcleId(state) {
      return state.tcleId;
    },
  },
};

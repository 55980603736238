<template>
  <v-card color="transparent ml-5 mr-5" flat tile>
    <v-card-title>
      Data Source
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <prism-editor
        class="my-editor height"
        v-model="datasource"
        :highlight="highlighter"
        line-numbers
      ></prism-editor>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="ml-8 mt-4"
        v-bind="attrs"
        v-on="on"
        dark
        color="secondary"
        elevation="0"
        @click="addDatasource()"
      >
        Adicionar
      </v-btn>
      <v-btn
        class="ml-8 mt-4"
        v-bind="attrs"
        v-on="on"
        dark
        color="warning"
        elevation="0"
        @click="$router.replace({ name: 'Datasources' })"
      >
        Voltar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../../../firebase";

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-funky.css";
const beautify = require("json-beautify");
let emptyDatasource = require("./datasource.json");

export default {
  data() {
    return {
      datasource: null,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    highlighter(code) {
      return highlight(code, languages.json); //returns html
    },
    addDatasource() {
      this.datasource = JSON.parse(this.datasource);
      db.collection("datasources")
        .add(this.datasource)
        .then(() => {
          this.$router.replace({ name: "Datasources" });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.datasource = beautify(emptyDatasource, null, 2, 0);
  },
};
</script>

<style>
.height {
  height: 650px;
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>

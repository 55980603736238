import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    contacts: [],
    contactId: null,
  },
  mutations: {
    SET_CONTACT_ID(state, value) {
      state.contactId = value;
    },
  },
  actions: {
    setContactId({ commit }, id) {
      commit("SET_CONTACT_ID", id);
    },
    bindContacts: firestoreAction((context) => {
      let ref = db.collection("contacts");
      context.bindFirestoreRef("contacts", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    contacts(state) {
      return state.contacts;
    },
    contactId(state) {
      return state.contactId;
    },
  },
};

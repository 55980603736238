import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
	state: {
		monitors: [],
		monitorId: null,
	},
	mutations: {
		SET_MONITOR_ID(state, value) {
			state.monitorId = value;
		},
	},
	actions: {
		setMonitorId({ commit }, id) {
			commit("SET_MONITOR_ID", id);
		},
		bindMonitors: firestoreAction((context) => {
			let ref = db.collection("monitors");
			context.bindFirestoreRef("monitors", ref, {
				wait: true,
				reset: true,
			});
		}),
	},
	getters: {
		monitors(state) {
			return state.monitors;
		},
		monitorId(state) {
			return state.monitorId;
		},
	},
};

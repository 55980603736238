<template>
  <v-card color="transparent" flat tile>
    <v-card-title>
      Usuário do Prestador de Serviços
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <prism-editor
        id="provider"
        class="my-editor height"
        v-model="provider"
        :highlight="highlighter"
        line-numbers
      ></prism-editor>
      <v-card-actions>
        <v-btn
          class="ml-n2"
          v-bind="attrs"
          v-on="on"
          dark
          color="secondary"
          elevation="0"
          @click="saveProvider()"
        >
          Adicionar
        </v-btn>
        <v-btn
          class="ml-2"
          v-bind="attrs"
          v-on="on"
          dark
          color="warning"
          elevation="0"
          @click="$router.replace({ name: 'Providers' })"
        >
          Voltar
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../firebase";

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-funky.css"; // import syntax highlighting styles
const beautify = require("json-beautify");
let emptyProvider = require("./provider.json");

export default {
  components: {},
  data() {
    return {
      provider: null,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    highlighter(code) {
      return highlight(code, languages.json);
    },
    saveProvider() {
      this.provider = JSON.parse(this.provider);
      let createProvider = functions.httpsCallable(
        "auth-admin-create-provider-api"
      );
      createProvider(this.provider)
        .then((result) => {
          this.$router.replace({ name: "Providers" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.provider = beautify(
      JSON.parse(JSON.stringify(emptyProvider)),
      null,
      2,
      0
    );
  },
};
</script>

<style>
.api {
  height: 400px;
  width: 670px;
}
.height {
  height: 450px;
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>

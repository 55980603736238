import {firestoreAction} from "vuexfire";
import {db} from "../../firebase";

export default {
    state: {
        calculatedFields: [],
        calculatedFieldId: null
    },
    mutations: {
        SET_CALCULATED_FIELD_ID(state, value) {
            state.calculatedFieldId = value;
        }
    },
    actions: {
        setCalculatedFieldId({
            commit
        }, id) {
            commit("SET_CALCULATED_FIELD_ID", id);
        },
        bindCalculatedFields: firestoreAction((context) => {
            let ref = db.collection("calculated-fields");
            context.bindFirestoreRef("calculatedFields", ref, {
                wait: true,
                reset: true
            });
        })
    },
    getters: {
        calculatedFields(state) {
            return state.calculatedFields;
        },
        calculatedFieldId(state) {
            return state.calculatedFieldId;
        }
    }
};

import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    rooms: [],
    roomId: null,
  },
  mutations: {
    SET_ROOM_ID(state, value) {
      state.roomId = value;
    },
  },
  actions: {
    setRoomId({ commit }, id) {
      commit("SET_ROOM_ID", id);
    },
    bindRooms: firestoreAction((context) => {
      let ref = db.collection("rooms");
      context.bindFirestoreRef("rooms", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    rooms(state) {
      return state.rooms;
    },
    roomId(state) {
      return state.roomId;
    },
  },
};

import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    customers: [],
    customerId: null,
  },
  mutations: {
    SET_CUSTOMER_ID(state, value) {
      state.customerId = value;
    },
  },
  actions: {
    setCustomerId({ commit }, id) {
      commit("SET_CUSTOMER_ID", id);
    },
    bindCustomers: firestoreAction((context) => {
      let ref = db.collection("customer");
      context.bindFirestoreRef("customers", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    customers(state) {
      return state.customers;
    },
    customerId(state) {
      return state.customerId;
    },
  },
};

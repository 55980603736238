<template>
  <v-card color="transparent ml-5 mr-5" flat tile>
    <v-card-title>
      Journey
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab href="#geral">General</v-tab>
        <v-tab href="#target">Target Group</v-tab>
        <v-tab href="#reason">Reason</v-tab>
        <v-tab href="#kpis">Kpis</v-tab>
        <v-tab href="#functions">Functions</v-tab>
        <v-tab href="#rooms">Rooms</v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item value="geral" class="mt-4">
            <v-text-field
              label="queryId"
              placeholder="queryId"
              outlined
              v-model="journey.queryId"
            ></v-text-field>
            <v-text-field
              label="type"
              placeholder="type"
              outlined
              v-model="journey.type"
            ></v-text-field>
            <v-text-field
              label="flow"
              placeholder="flow"
              outlined
              v-model="journey.flow"
            ></v-text-field>
            <v-text-field
              label="text"
              placeholder="text"
              outlined
              v-model="journey.text"
            ></v-text-field>
            <v-tab-item value="rooms">
              <prism-editor
                class="my-editor height"
                v-model="rooms"
                :highlight="highlighter"
                line-numbers
              >
              </prism-editor>
            </v-tab-item>
            <v-switch
              class="ml-4"
              v-model="journey.dev"
              :label="`DEV? ${journey.dev.toString()}`"
            ></v-switch>
          </v-tab-item>
          <v-tab-item value="target">
            <prism-editor
              class="my-editor height"
              v-model="targetGroup"
              :highlight="highlighter"
              line-numbers
            >
            </prism-editor>
          </v-tab-item>
          <v-tab-item value="reason">
            <prism-editor
              class="my-editor height"
              v-model="reason"
              :highlight="highlighter"
              line-numbers
            >
            </prism-editor>
          </v-tab-item>
          <v-tab-item value="kpis">
            <prism-editor
              class="my-editor height"
              v-model="kpis"
              :highlight="highlighter"
              line-numbers
            >
            </prism-editor>
          </v-tab-item>
          <v-tab-item value="functions">
            <prism-editor
              class="my-editor height"
              v-model="functions"
              :highlight="highlighter"
              line-numbers
            >
            </prism-editor>
          </v-tab-item>
          <v-tab-item value="rooms">
            <prism-editor
              class="my-editor height"
              v-model="rooms"
              :highlight="highlighter"
              line-numbers
            >
            </prism-editor>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="ml-8 mt-10"
        v-bind="attrs"
        v-on="on"
        dark
        color="secondary"
        elevation="0"
        @click="saveJourney()"
      >
        Salvar
      </v-btn>
      <v-btn
        class="ml-8 mt-10"
        v-bind="attrs"
        v-on="on"
        dark
        color="warning"
        elevation="0"
        @click="$router.replace({ name: 'Journeys' })"
      >
        Voltar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../../../firebase";

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-funky.css"; // import syntax highlighting styles
const beautify = require("json-beautify");

export default {
  data() {
    return {
      tab: null,
      journey: null,
      targetGroup: null,
      reason: null,
      kpis: null,
      functions: null,
      rooms: null,
    };
  },
  computed: {
    ...mapGetters({
      journeyId: "journeyId",
    }),
  },
  watch: {},
  methods: {
    highlighter(code) {
      return highlight(code, languages.json); //returns html
    },
    saveJourney() {
      this.journey.targetGroup = JSON.parse(this.targetGroup);
      this.journey.reason = JSON.parse(this.reason);
      this.journey.kpis = JSON.parse(this.kpis);
      this.journey.functions = JSON.parse(this.functions);
      this.journey.rooms = JSON.parse(this.rooms);
      db.collection("journey")
        .doc(this.journeyId)
        .update(this.journey)
        .then(() => {
          this.$router.replace({ name: "Journeys" });
        });
    },
  },
  async mounted() {},
  async created() {
    db.collection("journey")
      .doc(this.journeyId)
      .get()
      .then(async (jn) => {
        this.targetGroup = beautify(jn.data().targetGroup, null, 2, 0);
        this.reason = beautify(jn.data().reason, null, 2, 0);
        this.kpis = beautify(jn.data().kpis, null, 2, 0);
        this.functions = beautify(jn.data().functions, null, 2, 0);
        this.rooms = beautify(jn.data().rooms, null, 2, 0);
        this.journey = jn.data();
      });
  },
};
</script>

<style>
.height {
  height: 650px;
}

/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
<template>
  <v-card color="transparent ml-5 mr-5" flat tile>
    <v-card-title>
      Recursos
      <v-btn
        class="ml-8"
        v-bind="attrs"
        v-on="on"
        dark
        color="primary"
        elevation="0"
        @click="$router.replace({ name: 'ResourceNew' })"
      >
        Adicionar
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Filtrar"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
      dense
      class="elevation-0"
      :headers="headers"
      :items="resources"
      :search="search"
      :items-per-page="40"
      :sort-by="['text']"
      :sort-desc="[true]"
      calculate-widths="true"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" color="primary" @click="editResource(item)">
          mdi-pencil
        </v-icon>
        <v-icon small class="mr-2" color="error" @click="deleteResource(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../../../firebase";

export default {
  data() {
    return {
      search: null,
      headers: [
        { text: "ID", value: "id", align: ' d-none' },
        { text: "Query Id", value: "queryId" },
        { text: "Source", value: "source" },
        { text: "Text", value: "text" },
        { text: "Resposta", value: "options[0].value" },
        { text: "Category", value: "category" },
        { text: "SubCategory", value: "subCategory" },
        { text: "item", value: "item" },
        { text: "key", value: "key" },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      resources: "resources",
    }),
  },
  watch: {},
  methods: {
    editResource(resource) {
      this.$store.dispatch("setResourceId", resource.id);
      this.$router.replace({ name: "Resource" });
    },
    deleteResource(resource) {
      this.$swal
        .fire({
          title: "Apagar?",
          text: "Você deseja apagar esse recurso?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then((result) => {
          if (result.value) {
            db.collection("resources")
              .doc(resource.id)
              .delete()
              .then(() => {
                this.$router.replace({ name: "Resources" });
              });
          }
        });
    },
  },
  created() {
    this.$store.dispatch("bindResources");
  },
};
</script>

import Monitors from "../../components/functional/monitor/Monitors.vue";
import Monitor from "../../components/functional/monitor/Monitor.vue";
import MonitorNew from "../../components/functional/monitor/New.vue";

export default [
  {
    path: "monitors",
    name: "Monitors",
    component: Monitors,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "monitor",
    name: "Monitor",
    component: Monitor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "monitorNew",
    name: "MonitorNew",
    component: MonitorNew,
    meta: {
      requiresAuth: true,
    },
  }
];

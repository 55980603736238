<template>
  <v-navigation-drawer mini-variant-width="80" class="height-app" app :permanent="true" :mini-variant="true"
    :expand-on-hover="true" :fixed="true" color="primary" :hide-overlay="true" dark>
    <v-list dense nav class="py-0">
      <v-list-item two-line :class="miniVariant && 'px-0'">
        <v-list-item-avatar class="profile rounded-lg" size="30">
          <v-icon>mdi-account-circle</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Administrador</v-list-item-title>
          <v-list-item-subtitle>Gestão de Configuração</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <template>
        <v-list-item link router to="/dashboard/customers">
          <v-list-item-action>
            <v-icon>mdi-account-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Customers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/professionals">
          <v-list-item-action>
            <v-icon>mdi-doctor</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Professionals</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/tcles">
          <v-list-item-action>
            <v-icon>mdi-shield-account-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Tcles</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/flows">
          <v-list-item-action>
            <v-icon>mdi-sitemap</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Flows</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/journeys">
          <v-list-item-action>
            <v-icon>mdi-map</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Journeys</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/calculatedFields">
          <v-list-item-action>
            <v-icon>mdi-calculator</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Calculated Fields</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/providers">
          <v-list-item-action>
            <v-icon>mdi-account-cog-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Providers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/contacts">
          <v-list-item-action>
            <v-icon>mdi-card-account-phone</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Contacts</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/companies">
          <v-list-item-action>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Companies</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/customerIds">
          <v-list-item-action>
            <v-icon>mdi-account-arrow-left-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Customer Ids</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/serviceProfiles">
          <v-list-item-action>
            <v-icon>mdi-menu</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Service Profiles</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/businessFlows">
          <v-list-item-action>
            <v-icon>mdi-apple-keyboard-option</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Business Flows</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/profiles">
          <v-list-item-action>
            <v-icon>mdi-security</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Profiles</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/rooms">
          <v-list-item-action>
            <v-icon>mdi-door-open</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Rooms</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/teles">
          <v-list-item-action>
            <v-icon>mdi-television-ambient-light </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Telemedicine</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/anamnesis">
          <v-list-item-action>
            <v-icon>mdi-message-bulleted</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Anamnesis</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/survey">
          <v-list-item-action>
            <v-icon>mdi-clipboard-text-search-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Survey</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/resources">
          <v-list-item-action>
            <v-icon>mdi-database</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Resources</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/dataViews">
          <v-list-item-action>
            <v-icon>mdi-eye</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Data Views</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/datasources">
          <v-list-item-action>
            <v-icon>mdi-database</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Data Sources</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/views">
          <v-list-item-action>
            <v-icon>mdi-monitor</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Views</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/monitors">
          <v-list-item-action>
            <v-icon>mdi-heart-pulse</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Monitors</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/memed">
          <v-list-item-action>
            <v-icon>mdi-clipboard-pulse-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Memed</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/primaryCares">
          <v-list-item-action>
            <v-icon>mdi-hospital-box-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Primary Cares</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/whatsappTemplates">
          <v-list-item-action>
            <v-icon>mdi-whatsapp</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Whatsapp Templates</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link router to="/dashboard/diseaseKnowledgeBase">
          <v-list-item-action>
            <v-icon>mdi-brain</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Disease Knowledge Base</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template>
        <v-divider dark class="my-4"></v-divider>
        <v-list-item link @click="signOut">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { auth } from "../../firebase";

export default {
  computed: {
    ...mapGetters({}),
  },
  methods: {
    signOut() {
      this.$swal
        .fire({
          title: "Sair do Sistema",
          text: "Você realmente deseja sair do iCareYou?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then((result) => {
          if (result.value) {
            auth.signOut().then(() => {
              this.$router.replace({
                name: "Login",
              });
            });
          }
        });
    },
  },
};
</script>

<style>

</style>

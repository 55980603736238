import Tcles from "../../components/functional/tcle/Tcles.vue";
import Tcle from "../../components/functional/tcle/Tcle.vue";
import TcleNew from "../../components/functional/tcle/New.vue";

export default [
  {
    path: "tcles",
    name: "Tcles",
    component: Tcles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "tcle",
    name: "Tcle",
    component: Tcle,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "tcleNew",
    name: "TcleNew",
    component: TcleNew,
    meta: {
      requiresAuth: true,
    },
  },
];

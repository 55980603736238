import Flow from "../../components/functional/flows/Flow.vue";
import Flows from "../../components/functional/flows/Flows.vue";
import FlowNew from "../../components/functional/flows/New.vue";

export default[
    {
        path: "flow",
        name: "Flow",
        component: Flow,
        meta: {
            requiresAuth: true
        }
    }, {
        path: "flows",
        name: "Flows",
        component: Flows,
        meta: {
            requiresAuth: true
        }
    }, {
        path: "flowNew",
        name: "FlowNew",
        component: FlowNew,
        meta: {
            requiresAuth: true
        }
    },
];

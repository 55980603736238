<template>
  <v-card color="transparent ml-5 mr-5" flat tile>
    <v-card-title>
      Clientes
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col><v-file-input v-model="file" show-size @change="getFileObject" label="Clientes" outlined>
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template></v-file-input></v-col>
        <v-col><v-text-field label="Número de clientes" outlined disabled v-model="size"></v-text-field></v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-progress-linear v-model="load" color="secondary" height="25">
        <template v-slot:default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
    </v-card-text>
    <v-card-text class="mt-n4">
      <v-btn @click="download()" class="ml-1" v-bind="attrs" v-on="on" elevation="0" color="primary" min-width="0"
        max-width="40" min-height="0" max-height="40">
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-data-table class="elevation-0" :headers="headers" :items="loads" :items-per-page="50" :sort-by="['createdAt']"
        :sort-desc="[false]" calculate-widths="true">
        <template v-slot:item.error="{ item }">
          <v-chip x-small label v-if="item.error" class="red white--text">
            {{ item.error }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="deleteCustomer(item)" class="ml-1" v-bind="attrs" v-on="on" elevation="0" color="warning"
            min-width="0" max-width="40" min-height="0" max-height="40">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn class="ml-8" v-bind="attrs" v-on="on" dark color="primary" elevation="0" @click="loadCustomers()">
        Carregar
      </v-btn>
      <v-btn class="ml-8" v-bind="attrs" v-on="on" dark color="secondary" elevation="0" @click="execute()">
        Executar
      </v-btn>
      <v-btn class="ml-8" v-bind="attrs" v-on="on" dark color="warning" elevation="0"
        @click="$router.replace({ name: 'Customers' })">
        Voltar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { db, functions } from "../../../firebase";
import csvDownload from 'json-to-csv-export'

export default {
  data() {
    return {
      file: null,
      size: null,
      index: 0,
      length: 0,
      load: 0,
      headers: [
        { text: "ID", value: "id", sortable: false },
        { text: "Erro", value: "error" },
        { text: "Nome", value: "fullName" },
        { text: "Sexo", value: "gender" },
        { text: "Contato", value: "phone" },
        { text: "Data Nascimento", value: "birthdate" },
        { text: "CPF", value: "cpf" },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      loads: "loads",
    }),
  },
  watch: {},
  methods: {
    download() {
      const dataToConvert = {
        data: this.loads,
        filename: 'error_report',
        delimiter: ';',
      }
      csvDownload(dataToConvert);
    },
    deleteCustomer(item) {
      db.collection("load")
        .doc(item.id)
        .delete();
    },
    updateLoad() {
      this.load = (this.index++ / this.length) * 100;
    },
    resetLoad(index, length) {
      this.load = 0;
      this.index = index;
      this.length = length;
    },
    getFileObject() {
      let reader = new FileReader();
      reader.readAsText(this.file);
      reader.onload = () => {
        this.customers = JSON.parse(reader.result);
        this.size = this.customers.length;
      };
    },
    async loadCustomers() {
      this.resetLoad(1, this.customers.length);
      for (let loadedCustomer of this.customers) {
        this.enrichCustomer(loadedCustomer);
        await db
          .collection("load")
          .add(loadedCustomer)
          .then(() => {
            this.updateLoad();
          });
      }
    },
    async enrichCustomer(customer) {
      customer.fullName = customer.fullName.toLowerCase();
      let words = customer.fullName.split(" ");
      customer.fullName = words.map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      }).join(" ");

      if (customer.gender == 'F') {
        customer.gender = 'Feminino'
      } else if (customer.gender == 'M') {
        customer.gender = 'Masculino'
      }
    },
    async execute() {
      let current_loads = await db.collection("load").get();
      this.resetLoad(1, current_loads.size);
      for (let index = 0; index < current_loads.size; index++) {
        let customer = current_loads.docs[index];
        let loadRef = db.collection("load").doc(customer.id);

        let customerNew = functions.httpsCallable("sis-customer-new-api");
        await customerNew({
          customer: customer.data(),
        })
          .then((result) => {
            db.collection("load")
              .doc(loadRef.id)
              .delete()
              .then(() => {
                return;
              });
          })
          .catch((err) => {
            loadRef.update({
              error: err.message
            }, { merge: true });
            console.error("O telefone não é mobile", err.message);
          });
        this.updateLoad();
      }
    },
  },
  created() { },
};
</script>

<style>
.height {
  height: 650px;
}

/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>

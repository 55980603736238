<template>
  <v-card color="transparent ml-5 mr-5" flat tile>
    <v-card-title>
      Usuários do Prestador de Serviços
      <v-btn
        class="ml-8"
        v-bind="attrs"
        v-on="on"
        dark
        color="primary"
        elevation="0"
        @click="$router.replace({ name: 'ProviderNew' })"
      >
        Adicionar
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Filtrar"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="elevation-0"
      :headers="headers"
      :items="providers"
      :search="search"
      :items-per-page="20"
      :sort-by="['createdAt']"
      :sort-desc="[false]"
      calculate-widths="true"
    >
      <template v-slot:item.phone="{ item }">
        {{ formatPhone(item.phone) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          @click="editProvider(item)"
          class="mr-1"
          v-bind="attrs"
          v-on="on"
          elevation="0"
          color="primary"
          min-width="0"
          max-width="40"
          min-height="0"
          max-height="40"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          @click="deleteProvider(item)"
          class="ml-1"
          v-bind="attrs"
          v-on="on"
          elevation="0"
          color="warning"
          min-width="0"
          max-width="40"
          min-height="0"
          max-height="40"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../firebase";
import { formatToPhone } from "brazilian-values";

export default {
  data() {
    return {
      search: null,
      headers: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Sobrenome", value: "lastName" },
        { text: "Contato", value: "phone" },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      providers: "providers",
    }),
  },
  watch: {},
  methods: {
    formatPhone(item) {
      return formatToPhone(item);
    },
    editProvider(provider) {
      this.$store.dispatch("setProviderId", provider.id);
      this.$router.replace({ name: "Provider" });
    },
    deleteProvider(provider) {
      this.$swal
        .fire({
          title: "Apagar?",
          text: "Você deseja apagar esse usuário do prestador de serviços?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then((result) => {
          if (result.value) {
            let deleteProvider = functions.httpsCallable(
              "auth-admin-delete-provider-api"
            );
            deleteProvider(provider)
              .then((result) => {
                this.$router.replace({ name: "Providers" });
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
    },
  },
  created() {
    this.$store.dispatch("bindProviders");
  },
};
</script>

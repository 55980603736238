import ServiceProfiles from "../../components/functional/serviceProfile/ServiceProfiles.vue";
import ServiceProfile from "../../components/functional/serviceProfile/ServiceProfile.vue";
import ServiceProfileNew from "../../components/functional/serviceProfile/New.vue";

export default [
  {
    path: "serviceProfiles",
    name: "ServiceProfiles",
    component: ServiceProfiles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "serviceProfile",
    name: "ServiceProfile",
    component: ServiceProfile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "serviceProfileNew",
    name: "ServiceProfileNew",
    component: ServiceProfileNew,
    meta: {
      requiresAuth: true,
    },
  },
];

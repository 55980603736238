import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
	state: {
		resources: [],
		resourceId: null,
	},
	mutations: {
		SET_RESOURCE_ID(state, value) {
			state.resourceId = value;
		},
	},
	actions: {
		setResourceId({ commit }, id) {
			commit("SET_RESOURCE_ID", id);
		},
		bindResources: firestoreAction((context) => {
			let ref = db.collection("resources");
			context.bindFirestoreRef("resources", ref, {
				wait: true,
				reset: true,
			});
		}),
	},
	getters: {
		resources(state) {
			return state.resources;
		},
		resourceId(state) {
			return state.resourceId;
		},
	},
};

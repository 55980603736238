import Anamnesis from "../../components/functional/anamnesis/Anamnesis.vue";
import _Anamnesis from "../../components/functional/anamnesis/_Anamnesis.vue";
import _AnamnesisNew from "../../components/functional/anamnesis/New.vue";

export default [
  {
    path: "anamnesis",
    name: "Anamnesis",
    component: Anamnesis,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "_anamnesis",
    name: "_Anamnesis",
    component: _Anamnesis,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "_anamnesisNew",
    name: "_AnamnesisNew",
    component: _AnamnesisNew,
    meta: {
      requiresAuth: true,
    },
  },
];

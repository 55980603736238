import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    dataViews: [],
    dataViewId: null,
  },
  mutations: {
    SET_DATA_VIEW_ID(state, value) {
      state.dataViewId = value;
    },
  },
  actions: {
    setDataViewId({ commit }, id) {
      commit("SET_DATA_VIEW_ID", id);
    },
    bindDataViews: firestoreAction((context) => {
      let ref = db.collection("data-views");
      context.bindFirestoreRef("dataViews", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    dataViews(state) {
      return state.dataViews;
    },
    dataViewId(state) {
      return state.dataViewId;
    },
  },
};

import Survey from "../../components/functional/survey/Survey.vue";
import _Survey from "../../components/functional/survey/_Survey.vue";
import _SurveyNew from "../../components/functional/survey/New.vue";

export default [
  {
    path: "survey",
    name: "Survey",
    component: Survey,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "_survey",
    name: "_Survey",
    component: _Survey,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "_surveyNew",
    name: "_SurveyNew",
    component: _SurveyNew,
    meta: {
      requiresAuth: true,
    },
  },
];

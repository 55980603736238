import Vue from "vue";
import Vuex from "vuex";
import {vuexfireMutations, firestoreOptions} from "vuexfire";

import customers from "./modules/customers";
import professionals from "./modules/professionals";
import companies from "./modules/companies";
import serviceProfiles from "./modules/serviceProfiles";
import rooms from "./modules/rooms";
import tcles from "./modules/tcles";
import primareCares from "./modules/primaryCares";
import resources from "./modules/resources";
import anamnesis from "./modules/anamnesis";
import load from "./modules/load";
import profiles from "./modules/profiles";
import businessFlows from "./modules/businessFlows";
import monitors from "./modules/monitors";
import whatsappTemplates from "./modules/whatsappTemplates";
import views from "./modules/views";
import datasources from "./modules/datasources";
import dataViews from "./modules/dataViews";
import diseaseKnowledgeBase from "./modules/diseaseKnowledgeBase";
import contacts from "./modules/contacts";
import providers from "./modules/providers";
import survey from "./modules/survey";
import journey from "./modules/journey";
import calculatedFields from "./modules/calculatedFields";
import flows from "./modules/flows";
import telemedicine from "./modules/telemedicine"

firestoreOptions.wait = true;

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        customers,
        professionals,
        companies,
        serviceProfiles,
        primareCares,
        rooms,
        resources,
        anamnesis,
        load,
        profiles,
        businessFlows,
        monitors,
        whatsappTemplates,
        views,
        datasources,
        dataViews,
        diseaseKnowledgeBase,
        contacts,
        providers,
        survey,
        tcles,
        journey,
        calculatedFields,
        flows,
        telemedicine
    },

    mutations: {
        ...vuexfireMutations
    }
});

export default store;

<template>
  <v-card color="transparent ml-5 mr-5" flat tile>
    <v-card-title>
      Anamnese
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab href="#geral">Geral</v-tab>
        <v-tab href="#questions">Questões</v-tab>
        <v-tab href="#steps">Passos</v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item value="geral" class="mt-4">
            <v-text-field
              label="queryId"
              placeholder="queryId"
              outlined
              v-model="anamnesis.queryId"
            ></v-text-field>
            <v-text-field
              label="healthHistory"
              placeholder="healthHistory"
              outlined
              v-model="healthHistory"
            ></v-text-field>
            <v-text-field
              label="start"
              placeholder="start"
              outlined
              v-model="anamnesis.start"
            ></v-text-field>
            <v-textarea
              outlined
              label="description"
              placeholder="description"
              v-model="anamnesis.description"
              rows="4"
            ></v-textarea>
          </v-tab-item>
          <v-tab-item value="questions">
            <prism-editor
              class="my-editor height"
              v-model="questions"
              :highlight="highlighter"
              line-numbers
            ></prism-editor
          ></v-tab-item>
          <v-tab-item value="steps">
            <prism-editor
              class="my-editor height"
              v-model="steps"
              :highlight="highlighter"
              line-numbers
            ></prism-editor>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="ml-8 mt-10"
        v-bind="attrs"
        v-on="on"
        dark
        color="secondary"
        elevation="0"
        @click="saveAnamnesis()"
      >
        Salvar
      </v-btn>
      <v-btn
        class="ml-8 mt-10"
        v-bind="attrs"
        v-on="on"
        dark
        color="warning"
        elevation="0"
        @click="$router.replace({ name: 'Anamnesis' })"
      >
        Voltar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../../../firebase";

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-funky.css"; // import syntax highlighting styles
const beautify = require("json-beautify");
let emptyAnamnesis = require("./anamnesis.json");

export default {
  data() {
    return {
      tab: null,
      anamnesis: null,
      questions: null,
      steps: null,
    };
  },
  computed: {
    healthHistory: {
      set(val) {
        this.anamnesis.healthHistory = val == "true";
      },
      get() {
        return this.anamnesis.healthHistory;
      },
    },
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    highlighter(code) {
      return highlight(code, languages.json); //returns html
    },
    saveAnamnesis() {
      this.anamnesis.questions = this.questions;
      this.anamnesis.steps = this.steps;
      db.collection("anamnesis")
        .add(this.anamnesis)
        .then(() => {
          this.$router.replace({ name: "Anamnesis" });
        });
    },
  },
  created() {
    this.questions = beautify(emptyAnamnesis.questions, null, 2, 0);
    this.steps = beautify(emptyAnamnesis.steps, null, 2, 0);
    this.anamnesis = emptyAnamnesis;
  },
};
</script>

<style>
.height {
  height: 650px;
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>

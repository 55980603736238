<template>
	<v-card color="transparent ml-5 mr-5" flat tile>
		<v-card-title>
			Calculated Fields
			<v-btn class="ml-8" v-bind="attrs" v-on="on" dark color="primary" elevation="0"
				@click="$router.replace({ name: 'CalculatedFieldNew' })">
				Adicionar
			</v-btn>
			<v-spacer></v-spacer>
			<v-text-field v-model="search" append-icon="mdi-magnify" label="Filtrar" single-line hide-details clearable>
			</v-text-field>
		</v-card-title>
		<v-data-table class="elevation-0" :headers="headers" :items="calculatedFields" :search="search"
			:items-per-page="20" :sort-by="['createdAt']" :sort-desc="[false]" calculate-widths="true">
			<template v-slot:item.actions="{ item }">
				<v-btn @click="editCalculatedField(item)" class="mr-1" v-bind="attrs" v-on="on" elevation="0"
					color="primary" min-width="0" max-width="40" min-height="0" max-height="40">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
				<v-btn @click="deleteCalculatedField(item)" class="ml-1" v-bind="attrs" v-on="on" elevation="0"
					color="warning" min-width="0" max-width="40" min-height="0" max-height="40">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../../../firebase";

export default {
	data() {
		return {
			search: null,
			headers: [
				{ text: "ID", value: "id" },
				{ text: "Query Id", value: "queryId" },
				{ text: "Name", value: "name" },
				{ text: "Info Type", value: "infoType" },
				{ text: "Decimal", value: "decimal" },
				{ text: "type", value: "type" },
				{ text: "Ações", value: "actions", sortable: false },
			],
		};
	},
	computed: {
		...mapGetters({
			calculatedFields: "calculatedFields",
		}),
	},
	watch: {},
	methods: {
		editCalculatedField(cf) {
			this.$store.dispatch("setCalculatedFieldId", cf.id);
			this.$router.replace({ name: "CalculatedField" });
		},
		deleteCalculatedField(cf) {
			this.$swal
				.fire({
					title: "Apagar?",
					text: "Você deseja apagar esse calculated field?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Sim",
					cancelButtonText: "Não",
				})
				.then((result) => {
					if (result.value) {
						db.collection("calculated-fields")
							.doc(cf.id)
							.delete()
							.then(() => {
								this.$router.replace({ name: "CalculatedFields" });
							});
					}
				});
		},
	},
	created() {
		this.$store.dispatch("bindCalculatedFields");
	},
};
</script>

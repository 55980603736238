import {firestoreAction} from "vuexfire";
import {db} from "../../firebase";

export default {
    state: {
        flows: [],
        flowId: null
    },
    mutations: {
        SET_FLOW_ID(state, value) {
            state.flowId = value;
        }
    },
    actions: {
        setFlowId({
            commit
        }, id) {
            commit("SET_FLOW_ID", id);
        },
        bindFlows: firestoreAction((context) => {
            let ref = db.collection("flows");
            context.bindFirestoreRef("flows", ref, {
                wait: true,
                reset: true
            });
        })
    },
    getters: {
        flows(state) {
            return state.flows;
        },
        flowId(state) {
            return state.flowId;
        }
    }
};

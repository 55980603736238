import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    companies: [],
    companyId: null,
  },
  mutations: {
    SET_COMPANY_ID(state, value) {
      state.companyId = value;
    },
  },
  actions: {
    setCompanyId({ commit }, id) {
      commit("SET_COMPANY_ID", id);
    },
    bindCompanies: firestoreAction((context) => {
      let ref = db.collection("companies");
      context.bindFirestoreRef("companies", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    companies(state) {
      return state.companies;
    },
    companyId(state) {
      return state.companyId;
    },
  },
};

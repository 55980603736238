import PrimaryCares from "../../components/functional/primarycare/PrimaryCares.vue";
import PrimaryCare from "../../components/functional/primarycare/PrimaryCare.vue";
import PrimaryCareNew from "../../components/functional/primarycare/New.vue";

export default [
  {
    path: "primaryCares",
    name: "PrimaryCares",
    component: PrimaryCares,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "primaryCare",
    name: "PrimaryCare",
    component: PrimaryCare,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "primaryCareNew",
    name: "PrimaryCareNew",
    component: PrimaryCareNew,
    meta: {
      requiresAuth: true,
    },
  },
];

import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    professionals: [],
    professionalId: null,
  },
  mutations: {
    SET_PROFESSIONAL_ID(state, value) {
      state.professionalId = value;
    },
  },
  actions: {
    setProfessionalId({ commit }, id) {
      commit("SET_PROFESSIONAL_ID", id);
    },
    bindProfessionals: firestoreAction((context) => {
      let ref = db.collection("professionals");
      context.bindFirestoreRef("professionals", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    professionals(state) {
      return state.professionals;
    },
    professionalId(state) {
      return state.professionalId;
    },
  },
};

import DiseaseKnowledgeBase from "../../components/functional/diseaseKnowledgeBase/DiseaseKnowledgeBase.vue";
import _DiseaseKnowledgeBase from "../../components/functional/diseaseKnowledgeBase/_DiseaseKnowledgeBase.vue";
import DiseaseKnowledgeBaseNew from "../../components/functional/diseaseKnowledgeBase/New.vue";

export default [
  {
    path: "diseaseKnowledgeBase",
    name: "DiseaseKnowledgeBase",
    component: DiseaseKnowledgeBase,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "_diseaseKnowledgeBase",
    name: "_DiseaseKnowledgeBase",
    component: _DiseaseKnowledgeBase,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "DiseaseKnowledgeBaseNew",
    name: "DiseaseKnowledgeBaseNew",
    component: DiseaseKnowledgeBaseNew,
    meta: {
      requiresAuth: true,
    },
  },
];

import Dashboard from "../../components/structural/Dashboard.vue";
import Customer from "./customer";
import CustomerId from "./customerId";
import Professional from "./professional";
import Company from "./company";
import ServiceProvider from "./serviceProfile";
import BusinessFlow from "./businessFlow";
import Room from "./room";
import Tcle from "./tcle";
import Profile from "./profile";
import WhatsappTemplate from "./whatsappTemplate";
import PrimaryCare from "./primaryCare";
import Resource from "./resource";
import Monitor from "./monitor";
import View from "./view";
import Anamnesis from "./anamnesis";
import Memed from "./memed";
import Datasource from "./datasource";
import DataView from "./dataView";
import DiseaseKnowledgeBase from "./diseaseKnowledgeBase";
import Contacts from "./contacts";
import Provider from "./provider";
import Survey from "./survey";
import Journey from "./journey";
import CalculatedFields from "./calculatedFields";
import Flows from "./flows";
import Telemedicine from "./telemedicine";

export default[{
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true
        },
        children: [
            ...CustomerId,
            ...Customer,
            ...Professional,
            ...Company,
            ...ServiceProvider,
            ...BusinessFlow,
            ...Room,
            ...Profile,
            ...WhatsappTemplate,
            ...PrimaryCare,
            ...Resource,
            ...Monitor,
            ...View,
            ...Anamnesis,
            ...Memed,
            ...Datasource,
            ...DataView,
            ...DiseaseKnowledgeBase,
            ...Contacts,
            ...Provider,
            ...Survey,
            ...Tcle,
            ...Journey,
            ...CalculatedFields,
            ...Flows,
            ...Telemedicine
        ]
    },];

import Rooms from "../../components/functional/room/Rooms.vue";
import Room from "../../components/functional/room/Room.vue";
import RoomNew from "../../components/functional/room/New.vue";

export default [
  {
    path: "rooms",
    name: "Rooms",
    component: Rooms,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "room",
    name: "Room",
    component: Room,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "roomNew",
    name: "RoomNew",
    component: RoomNew,
    meta: {
      requiresAuth: true,
    },
  },
];

<template>
  <v-card color="transparent ml-5 mr-5" flat tile>
    <v-card-title>
      Flow
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab href="#geral">General</v-tab>
        <v-tab href="#moments">Moments</v-tab>
        <v-tab href="#steps">Steps</v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item value="geral" class="mt-4">
            <v-text-field label="queryId" placeholder="queryId" outlined v-model="flow.queryId"></v-text-field>
            <v-text-field label="start" placeholder="start" outlined v-model="flow.start"></v-text-field>
            <v-text-field label="text" placeholder="text" outlined v-model="flow.text"></v-text-field>
          </v-tab-item>
          <v-tab-item value="moments">
            <prism-editor class="my-editor height" v-model="moments" :highlight="highlighter" line-numbers>
            </prism-editor>
          </v-tab-item>
          <v-tab-item value="steps">
            <prism-editor class="my-editor height" v-model="steps" :highlight="highlighter" line-numbers></prism-editor>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
    <v-card-actions>
      <v-btn class="ml-8 mt-10" v-bind="attrs" v-on="on" dark color="secondary" elevation="0" @click="saveFlow()">
        Salvar
      </v-btn>
      <v-btn class="ml-8 mt-10" v-bind="attrs" v-on="on" dark color="warning" elevation="0"
        @click="$router.replace({ name: 'Flows' })">
        Voltar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../../../firebase";

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-funky.css"; // import syntax highlighting styles
const beautify = require("json-beautify");
let emptyFlow = require("./flow.json");

export default {
  data() {
    return {
      tab: null,
      flow: null,
      moments: null,
      steps: null,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    highlighter(code) {
      return highlight(code, languages.json); //returns html
    },
    saveFlow() {
      this.flow.moments = JSON.parse(this.moments);
      this.flow.steps = JSON.parse(this.steps);

      db.collection("flows")
        .add(this.flow)
        .then(() => {
          this.$router.replace({ name: "Flows" });
        });
    },
  },
  created() {
    this.moments = beautify(emptyFlow.moments, null, 2, 0);
    this.steps = beautify(emptyFlow.steps, null, 2, 0);
    this.flow = emptyFlow;
  },
};
</script>

<style>
.height {
  height: 650px;
}

/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
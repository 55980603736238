import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    journeys: [],
    journeyId: null,
  },
  mutations: {
    SET_JOURNEY_ID(state, value) {
      state.journeyId = value;
    },
  },
  actions: {
    setJourneyId({ commit }, id) {
      commit("SET_JOURNEY_ID", id);
    },
    bindJourneys: firestoreAction((context) => {
      let ref = db.collection("journey");
      context.bindFirestoreRef("journeys", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    journeys(state) {
      return state.journeys;
    },
    journeyId(state) {
      return state.journeyId;
    },
  },
};

import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    whatsappTemplates: [],
    whatsappTemplateId: null,
  },
  mutations: {
    SET_WHATSAPP_TEMPLATE_ID(state, value) {
      state.whatsappTemplateId = value;
    },
  },
  actions: {
    setWhatsappTemplateId({ commit }, id) {
      commit("SET_WHATSAPP_TEMPLATE_ID", id);
    },
    bindWhatsappTemplates: firestoreAction((context) => {
      let ref = db.collection("whatsapp-templates");
      context.bindFirestoreRef("whatsappTemplates", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    whatsappTemplates(state) {
      return state.whatsappTemplates;
    },
    whatsappTemplateId(state) {
      return state.whatsappTemplateId;
    },
  },
};

import DataViews from "../../components/functional/dataView/DataViews.vue";
import DataView from "../../components/functional/dataView/DataView.vue";
import DataViewNew from "../../components/functional/dataView/New.vue";

export default [
  {
    path: "dataViews",
    name: "DataViews",
    component: DataViews,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "dataView",
    name: "DataView",
    component: DataView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "dataViewNew",
    name: "DataViewNew",
    component: DataViewNew,
    meta: {
      requiresAuth: true,
    },
  },
];

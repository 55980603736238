import Memed from "../../components/functional/memed/Memed.vue";

export default [
  {
    path: "memed",
    name: "Memed",
    component: Memed,
    meta: {
      requiresAuth: true,
    },
  },
];

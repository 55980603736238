<template>
  <v-card color="transparent ml-5 mr-5" flat tile>
    <v-card-title>
      Templates
      <v-btn
        class="ml-8"
        v-bind="attrs"
        v-on="on"
        dark
        color="primary"
        elevation="0"
        @click="sync()"
      >
        Sincronizar
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Filtrar"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="elevation-0"
      :headers="headers"
      :items="whatsappTemplates"
      :search="search"
      :items-per-page="10"
      :sort-by="['createdAt']"
      :sort-desc="[false]"
      calculate-widths="true"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          @click="viewTemplate(item)"
          class="mr-1"
          v-bind="attrs"
          v-on="on"
          elevation="0"
          color="primary"
          min-width="0"
          max-width="40"
          min-height="0"
          max-height="40"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          @click="deleteTemplate(item)"
          class="ml-1"
          v-bind="attrs"
          v-on="on"
          elevation="0"
          color="warning"
          min-width="0"
          max-width="40"
          min-height="0"
          max-height="40"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions, db } from "../../../firebase";

export default {
  data() {
    return {
      search: null,
      headers: [
        { text: "ID", value: "id", width:"300" },
        { text: "Nome", value: "name", width:"150" },
        { text: "Texto", value: "text",width:"780" },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      whatsappTemplates: "whatsappTemplates",
    }),
  },
  watch: {},
  methods: {
    viewTemplate(template) {
      this.$store.dispatch("setWhatsappTemplateId", template.id);
      this.$router.replace({ name: "WhatsappTemplate" });
    },
    sync() {
      let syncManager = functions.httpsCallable("zenvia-template-sync-api");
      syncManager()
        .then((result) => {
          console.info(result.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteTemplate(template) {
      this.$swal
        .fire({
          title: "Apagar?",
          text: "Você deseja apagar esse template do whatsapp?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then((result) => {
          if (result.value) {
            db.collection("whatsapp-templates")
              .doc(template.id)
              .delete()
              .then((result) => {
                this.$router.replace({ name: "WhatsappTemplates" });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    },
  },
  created() {
    this.$store.dispatch("bindWhatsappTemplates");
  },
};
</script>

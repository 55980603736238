<template>
  <v-card color="transparent ml-5 mr-5" flat tile>
    <v-card-title>
      Empresas
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-file-input
        v-model="file"
        show-size
        @change="getFileObject"
        label="Empresas"
        outlined
      >
        <template v-slot:selection="{ text }">
          <v-chip small label color="primary">
            {{ text }}
          </v-chip>
        </template></v-file-input
      >
    </v-card-text>
    <v-card-text>
      <v-text-field
        label="Número de empresas"
        outlined
        disabled
        v-model="size"
      ></v-text-field>
    </v-card-text>
    <v-card-text>
      <v-progress-linear v-model="load" color="blue-grey" height="25">
        <template v-slot:default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="ml-8"
        v-bind="attrs"
        v-on="on"
        dark
        color="primary"
        elevation="0"
        @click="loadCompanies()"
      >
        Carregar
      </v-btn>
      <v-btn
        class="ml-8"
        v-bind="attrs"
        v-on="on"
        dark
        color="warning"
        elevation="0"
        @click="$router.replace({ name: 'Companies' })"
      >
        Voltar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "../../../firebase";
let emptyCompany = require("./company.json");

export default {
  data() {
    return {
      file: null,
      companies: null,
      size: null,
      load: 0,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    getFileObject() {
      let reader = new FileReader();
      reader.readAsText(this.file);
      reader.onload = () => {
        this.companies = JSON.parse(reader.result);
        this.size = this.companies.length;
      };
    },
    loadCompanies() {
      let percent = 0;
      this.companies.forEach(async (company) => {
        this.load = (++percent / this.size) * 100;
        await this.addCompany(company);
      });
      this.$router.replace({ name: "Companies" });
    },
    async addCompany(loadedCompany) {
      let newCompany = JSON.parse(JSON.stringify(emptyCompany));
      let id = loadedCompany.cnpj.replaceAll(".", "-").replaceAll("/", "-");

      newCompany.phone = loadedCompany.phone;
      newCompany.commercialName = loadedCompany.commercialName;
      newCompany.defaultServiceProfile = loadedCompany.defaultServiceProfile;
      newCompany.name = loadedCompany.name;
      newCompany.address = loadedCompany.address;
      newCompany.addressNumber = loadedCompany.addressNumber;
      newCompany.addressComplement = loadedCompany.addressComplement;
      newCompany.neighborhood = loadedCompany.neighborhood;
      newCompany.zipCode = loadedCompany.zipCode;
      newCompany.city = loadedCompany.city;
      newCompany.state = loadedCompany.state;
      newCompany.cnpj = loadedCompany.cnpj;
      await db.collection("companies").doc(id).set(newCompany);
    },
  },
  created() {},
};
</script>

<style>
.height {
  height: 650px;
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style> 
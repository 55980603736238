import Companies from "../../components/functional/company/Companies.vue";
import Company from "../../components/functional/company/Company.vue";
import CompanyNew from "../../components/functional/company/New.vue";
import CompanyBatch from "../../components/functional/company/Batch.vue";

export default [
  {
    path: "companies",
    name: "Companies",
    component: Companies,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "company",
    name: "Company",
    component: Company,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "companyNew",
    name: "CompanyNew",
    component: CompanyNew,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "companyBatch",
    name: "CompanyBatch",
    component: CompanyBatch,
    meta: {
      requiresAuth: true,
    },
  },
];

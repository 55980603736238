import {firestoreAction} from "vuexfire";
import {db} from "../../firebase";

export default {
    state: {
        teles: [],
        teleId: null
    },
    mutations: {
        SET_TELE_ID(state, value) {
            state.teleId = value;
        }
    },
    actions: {
        setTeleId({
            commit
        }, id) {
            commit("SET_TELE_ID", id);
        },
        bindTeles: firestoreAction((context) => {
            let ref = db.collection("telemedicine");
            context.bindFirestoreRef("teles", ref, {
                wait: true,
                reset: true
            });
        })
    },
    getters: {
        teles(state) {
            return state.teles;
        },
        teleId(state) {
            return state.teleId;
        }
    }
};

import Teles from "../../components/functional/telemedicine/Teles.vue";
import Tele from "../../components/functional/telemedicine/Tele.vue";
import TeleNew from "../../components/functional/telemedicine/New.vue";

export default [
  {
    path: "teles",
    name: "Teles",
    component: Teles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "tele",
    name: "Tele",
    component: Tele,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "teleNew",
    name: "TeleNew",
    component: TeleNew,
    meta: {
      requiresAuth: true,
    },
  },
];

import Journey from "../../components/functional/journey/Journey.vue";
import Journeys from "../../components/functional/journey/Journeys.vue";
import JourneyNew from "../../components/functional/journey/New.vue";

export default [
  {
    path: "journey",
    name: "Journey",
    component: Journey,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "journeys",
    name: "Journeys",
    component: Journeys,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "journeyNew",
    name: "JourneyNew",
    component: JourneyNew,
    meta: {
      requiresAuth: true,
    },
  },
];

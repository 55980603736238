import Datasources from "../../components/functional/datasource/Datasources.vue";
import Datasource from "../../components/functional/datasource/Datasource.vue";
import DatasourceNew from "../../components/functional/datasource/New.vue";

export default [
  {
    path: "datasources",
    name: "Datasources",
    component: Datasources,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "datasource",
    name: "Datasource",
    component: Datasource,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "DatasourceNew",
    name: "DatasourceNew",
    component: DatasourceNew,
    meta: {
      requiresAuth: true,
    },
  },
];

import BusinessFlows from "../../components/functional/businessFlow/BusinessFlows.vue";
import BusinessFlow from "../../components/functional/businessFlow/BusinessFlow.vue";
import BusinessFlowNew from "../../components/functional/businessFlow/New.vue";

export default [
  {
    path: "businessFlows",
    name: "BusinessFlows",
    component: BusinessFlows,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "businessFlow",
    name: "BusinessFlow",
    component: BusinessFlow,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "businessFlowNew",
    name: "BusinessFlowNew",
    component: BusinessFlowNew,
    meta: {
      requiresAuth: true,
    },
  },
];

import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
  state: {
    profiles: [],
    profileId: null,
  },
  mutations: {
    SET_PROFILE_ID(state, value) {
      state.profileId = value;
    },
  },
  actions: {
    setProfileId({ commit }, id) {
      commit("SET_PROFILE_ID", id);
    },
    bindProfiles: firestoreAction((context) => {
      let ref = db.collection("profiles");
      context.bindFirestoreRef("profiles", ref, {
        wait: true,
        reset: true,
      });
    }),
  },
  getters: {
    profiles(state) {
      return state.profiles;
    },
    profileId(state) {
      return state.profileId;
    },
  },
};

<template>
	<v-card color="transparent ml-5 mr-5" flat tile>
		<v-card-title>
			Profissionais
			<v-btn class="ml-8" v-bind="attrs" v-on="on" dark color="primary" elevation="0"
				@click="$router.replace({ name: 'ProfessionalNew' })">
				Adicionar
			</v-btn>
			<v-spacer></v-spacer>
			<v-text-field v-model="search" append-icon="mdi-magnify" label="Filtrar" single-line hide-details
				clearable></v-text-field>
		</v-card-title>
		<v-data-table class="elevation-0" :headers="headers" :items="professionals" :search="search"
			:items-per-page="20" :sort-by="['createdAt']" :sort-desc="[false]" calculate-widths="true">
			<template v-slot:item.phone="{ item }">
				{{ formatPhone(item.phone) }}
			</template>
			<template v-slot:item.actions="{ item }">
				<v-btn @click="editProfessional(item)" class="mr-1" v-bind="attrs" v-on="on" elevation="0"
					color="primary" min-width="0" max-width="40" min-height="0" max-height="40">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
				<v-btn @click="deleteProfessional(item)" class="ml-1" v-bind="attrs" v-on="on" elevation="0"
					color="warning" min-width="0" max-width="40" min-height="0" max-height="40">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../../../firebase";
import { formatToPhone } from "brazilian-values";

export default {
	data() {
		return {
			search: null,
			headers: [
				{ text: "ID", value: "id" },
				{ text: "Dev", value: "dev" },
				{ text: "MEMED", value: "memedActive" },
				{ text: "Tipo", value: "professionalDesc" },
				{ text: "Nome", value: "name" },
				{ text: "Sobrenome", value: "lastName" },
				{ text: "Conselho", value: "professionalId" },
				{ text: "Contato", value: "phone" },
				{ text: "Cidade", value: "city" },
				{ text: "State", value: "state" },
				{ text: "Ações", value: "actions", sortable: false },
			],
		};
	},
	computed: {
		...mapGetters({
			professionals: "professionals",
		}),
	},
	watch: {},
	methods: {
		formatPhone(item) {
			return formatToPhone(item);
		},
		editProfessional(professional) {
			this.$store.dispatch("setProfessionalId", professional.id);
			this.$router.replace({ name: "Professional" });
		},
		deleteProfessional(professional) {
			this.$swal
				.fire({
					title: "Apagar?",
					text: "Você deseja apagar esse profissional?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Sim",
					cancelButtonText: "Não",
				})
				.then((result) => {
					if (result.value) {
						let deleteProfessional = functions.httpsCallable("auth-admin-delete-professional-api");
						deleteProfessional({
							professional: professional,
						})
							.then((result) => {
								this.$router.replace({ name: "Professionals" });
							})
							.catch((error) => {
								console.error(error);
							});
					}
				});
		},
	},
	created() {
		this.$store.dispatch("bindProfessionals");
	},
};
</script>

import Professionals from "../../components/functional/professional/Professionals.vue";
import Professional from "../../components/functional/professional/Professional.vue";
import ProfessionalNew from "../../components/functional/professional/New.vue";

export default [
  {
    path: "professionals",
    name: "Professionals",
    component: Professionals,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "professional",
    name: "Professional",
    component: Professional,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "professionalNew",
    name: "ProfessionalNew",
    component: ProfessionalNew,
    meta: {
      requiresAuth: true,
    },
  },
];

import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
	state: {
		businessFlows: [],
		businessFlowId: null,
	},
	mutations: {
		SET_BUSINESS_FLOW_ID(state, value) {
			state.businessFlowId = value;
		},
	},
	actions: {
		setBusinessFlowId({ commit }, id) {
			commit("SET_BUSINESS_FLOW_ID", id);
		},
		bindBusinessFlows: firestoreAction((context) => {
			let ref = db.collection("business-flows");
			context.bindFirestoreRef("businessFlows", ref, {
				wait: true,
				reset: true,
			});
		}),
	},
	getters: {
		businessFlows(state) {
			return state.businessFlows;
		},
		businessFlowId(state) {
			return state.businessFlowId;
		},
	},
};

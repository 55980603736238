import { firestoreAction } from "vuexfire";
import { db } from "../../firebase";

export default {
	state: {
		loads: [],
	},
	actions: {
		bindLoads: firestoreAction((context) => {
			let ref = db.collection("load");
			context.bindFirestoreRef("loads", ref, {
				wait: true,
				reset: true,
			});
		}),
	},
	getters: {
		loads(state) {
			return state.loads;
		},
	},
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"transparent ml-5 mr-5","flat":"","tile":""}},[_c('v-card-title',[_vm._v(" Clientes "),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-file-input',{attrs:{"show-size":"","label":"Clientes","outlined":""},on:{"change":_vm.getFileObject},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Número de clientes","outlined":"","disabled":""},model:{value:(_vm.size),callback:function ($$v) {_vm.size=$$v},expression:"size"}})],1)],1)],1),_c('v-card-text',[_c('v-progress-linear',{attrs:{"color":"secondary","height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}]),model:{value:(_vm.load),callback:function ($$v) {_vm.load=$$v},expression:"load"}})],1),_c('v-card-text',{staticClass:"mt-n4"},[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"elevation":"0","color":"primary","min-width":"0","max-width":"40","min-height":"0","max-height":"40"},on:{"click":function($event){return _vm.download()}}},'v-btn',_vm.attrs,false),_vm.on),[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.loads,"items-per-page":50,"sort-by":['createdAt'],"sort-desc":[false],"calculate-widths":"true"},scopedSlots:_vm._u([{key:"item.error",fn:function(ref){
var item = ref.item;
return [(item.error)?_c('v-chip',{staticClass:"red white--text",attrs:{"x-small":"","label":""}},[_vm._v(" "+_vm._s(item.error)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"elevation":"0","color":"warning","min-width":"0","max-width":"40","min-height":"0","max-height":"40"},on:{"click":function($event){return _vm.deleteCustomer(item)}}},'v-btn',_vm.attrs,false),_vm.on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-8",attrs:{"dark":"","color":"primary","elevation":"0"},on:{"click":function($event){return _vm.loadCustomers()}}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" Carregar ")]),_c('v-btn',_vm._g(_vm._b({staticClass:"ml-8",attrs:{"dark":"","color":"secondary","elevation":"0"},on:{"click":function($event){return _vm.execute()}}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" Executar ")]),_c('v-btn',_vm._g(_vm._b({staticClass:"ml-8",attrs:{"dark":"","color":"warning","elevation":"0"},on:{"click":function($event){return _vm.$router.replace({ name: 'Customers' })}}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" Voltar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }